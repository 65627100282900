<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        a) Using the data provided in the table below, determine
        <stemble-latex content="$\Delta\text{G}^\circ_{\text{rxn}}$" />
        for the following reaction.
      </p>

      <p class="pl-14 mb-5">
        <stemble-latex content="$\ce{A + B -> C}$" />
      </p>

      <v-simple-table class="mb-6" style="width: 300px">
        <thead>
          <tr>
            <th style="font-size: 15px">
              <stemble-latex content="$\text{Compound}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\Delta\text{G}^\circ_{\text{f}}\text{ (kJ/mol)}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{  A}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="gibbsA" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{  B}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="gibbsB" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{  C}$" />
            </td>
            <td style="text-align: center">
              <number-value :value="gibbsC" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <calculation-input
        v-model="inputs.Grxn"
        class="mb-6"
        prepend-text="$\Delta\text{G}^\circ_{\text{rxn}}:$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) If both
        <stemble-latex content="$\Delta\text{H}^\circ_{\text{rxn}}$" />
        and
        <stemble-latex content="$\Delta\text{S}^\circ_{\text{rxn}}$" />
        are negative, which component makes it spontaneous and in which direction is the reaction
        spontaneous?
      </p>

      <v-radio-group v-model="inputs.input2" class="pl-8" :disabled="!allowEditing">
        <v-radio
          v-for="option in options1"
          :key="option.value"
          class="d-inline-block"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.expression" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question287',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Grxn: null,
        input2: null,
      },
      options1: [
        {expression: 'Enthalpy driven to the left', value: 'hleft'},
        {expression: 'Enthalpy driven to the right', value: 'hright'},
        {expression: 'Entropy driven to the left', value: 'sleft'},
        {expression: 'Entropy driven to the right', value: 'sright'},
      ],
    };
  },
  computed: {
    gibbsA() {
      return this.taskState.getValueBySymbol('gibbsA').content;
    },
    gibbsB() {
      return this.taskState.getValueBySymbol('gibbsB').content;
    },
    gibbsC() {
      return this.taskState.getValueBySymbol('gibbsC').content;
    },
  },
};
</script>
